import React from 'react'
import Seo from '../components/Seo'

const Work = () => {

    return <>
        <Seo title="JumpOff, web development portfolio by Jesse Lee" />
        <article className="jo-content jo-post-content-wrapper">
            <h1>Work</h1>  
       </article>
    </>
}

export default Work
