import React from 'react'
import {Link} from 'gatsby'
import LinkButton from './LinkButton'
import {getPrettyDate, addEllipsis} from '../lib/utilities'
import styled from 'styled-components'

const ListPost = (props) => {

  const id = props.post.id;
  const slug = props.post.slug;
  const postID = 'jo-post-id_' + id;
  const title = props.post.title;
  const excerpt = addEllipsis(props.post.excerpt);
  const date = props.post.date;
  const prettyDate = getPrettyDate(date);
  const featuredImage = props.post.featuredImage;
  const postLink = '/blog/' + slug;

  return <StyledListPost id={postID} data-post-id={id} className="jo-list-post" >
    <img className="jo-list-post-featured-img" src={featuredImage.sourceUrl} alt={title}/>
    <div className="jo-list-post-content">
      <div className="jo-list-post-title">
        <Link to={postLink}>
          <h1 className="black-box-text" dangerouslySetInnerHTML={{ __html: title }}/>
        </Link>
      </div>
      <div className="jo-list-post-excerpt-wrapper">
        <div className="jo-list-post-excerpt black-box-text" dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
      <div className="jo-list-post-content-spacer"></div>
    </div>
    <div className="jo-list-post-meta">
      <div className="jo-list-post-date">
        <p className="black-box-text" dangerouslySetInnerHTML={{ __html: prettyDate }}/>
      </div>
      <div className="jo-list-post-read-more">
        <LinkButton to={postLink} text="Read More"/>
      </div>
    </div>
    <div className="jo-list-post-sizer"></div>
  </StyledListPost>
  
}

export default ListPost;

const StyledListPost = styled.article`
  .jo-list-post {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 60px auto;
    background-size: cover;
    background-position: center;
    box-shadow: -.5rem .5rem 3.6rem 0.6rem rgba(0,0,0,.12);
  }
  .jo-list-post .jo-list-post-sizer {
    width: 100%;
    height: 0px;
    padding-bottom: 66.66%;
    opacity:0;
    z-index: -1;
    align-self: flex-end;
  }
  .jo-list-post .jo-list-post-featured-img {
    width: 100%;
    height: auto;
    position: absolute;
  }
  .jo-list-post .jo-list-post-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 24px;
    text-align: left;
    top: 0;
    left: 0;
    padding-right: 10px;
    box-sizing: border-box;
    min-height: 100%;
    height: auto;
  }
  .jo-list-post .jo-list-post-meta {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    overflow: visible;
  }
  .jo-list-post .jo-list-post-title {
    align-self: flex-start;
  }
  .jo-list-post .jo-list-post-excerpt-wrapper {
    align-self: center;
    margin-top: 40px;
  }
  .jo-list-post .jo-list-post-content-spacer {
    margin-top: 64px;
  }
  .jo-list-post .jo-list-post-read-more {
    position: absolute;
    bottom: -10px;
    right: -16px;
    width: auto;
    z-index: 30;
  }
  .jo-list-post .jo-link-button-wrapper{
    position: relative;
    left:0;
  }
  .jo-list-post h1 {
    font-size: 2.5rem;
    text-transform: uppercase; 
  }
  .jo-list-post .jo-list-post-excerpt p {
    line-height: 140%;
    font-size: 1.3em;
  }
  .jo-list-post .jo-list-post-date {
    position: absolute;
    right: 0px;
    top: 28px;
  }
  .jo-list-post .jo-list-post-date .black-box-text {
    padding: 8px 0px;
    box-shadow: 12px 0 0 #191919, -12px 0 0 #191919;
  }

  @media screen and (min-width: 601px) and ( max-width: 800px ) { 
    .jo-list-post h1 {
      font-size: 2.1rem;
    }
    .jo-list-post .jo-list-post-excerpt p {
      font-size: 1.1em;
    }
    
  }
  @media screen and ( max-width: 800px ) { 
  
    .jo-list-post {
      flex-direction: column;
    }
    .jo-list-post .jo-list-post-featured-img, .jo-list-post-content, .jo-list-post .jo-list-post-meta {
      position: relative;
    }
    .jo-list-post .jo-list-post-title {
    z-index: 40; 
    }
    .jo-list-post .jo-list-post-content {
      padding: 0;
      margin-top: -100px;
      width: 100%; 
    }
    .jo-list-post .jo-list-post-excerpt-wrapper {
      margin-top: 0px;
      background: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      width: 100%;
    }
    .jo-list-post .jo-list-post-excerpt-wrapper .black-box-text p {
      padding: 20px 10px 20px;
      box-sizing: border-box;
      background: none;
      color: #191919;
      box-shadow: none;
    }
    .jo-list-post .jo-list-post-date {
      display: none;
    }
    .jo-list-post .jo-list-post-meta {
      width: 100%;
      height: 0px;
      right: -20px;
      top: -30px;
    }
    .jo-list-post .jo-list-post-read-more {
      position: relative;
      right: 0;
      bottom: 0;
      text-align: right;
    }
    .jo-list-post .jo-link-button-wrapper {
      right: 0;
      display: inline-block;
    }
    .jo-list-post .jo-list-post-sizer, .jo-list-post .jo-list-post-content-spacer {
      display: none;
    }
    
  }
  @media screen and (max-width: 600px) {
    .jo-list-post h1 {
      font-size: 2rem;
    }
    .jo-list-post .jo-list-post-excerpt p {
      font-size: 1em;
    }
  }
`